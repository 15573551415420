<template>
  <div class="tag-tab shared-index">
    <el-row class="table-title" type="flex" align="center">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane name="tags">
            <div slot="label">
              Thẻ Tags
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createTag"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleChangeTab"
        >Làm mới</el-button>
      </div>
    </el-row>
    <list-tags
      :loading="callingAPI"
      :data="allTags"
      @reload="handleReloadAfterDelete"
      @update="updateTag"
    ></list-tags>
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <update-tag-popup
      v-if="visibleUpdate"
      :tag="selectedTag"
      :visible="visibleUpdate"
      @close="visibleUpdate = false"
      @reload="loadData"
      @reload-constant="handleReloadConstant"
    ></update-tag-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import { getTags } from '@/services/tag'
import ListTags from './components/List.vue'
import UpdateTagPopup from './components/popup/Update.vue'

export default {
  name: 'Tags',
  components: {
    ListTags,
    UpdateTagPopup
  },
  data() {
    return {
      visibleUpdate: false,
      callingAPI: false,
      allTags: [],
      selectedTag: {},
      activeTab: 'tags',
      total: 0,
      limit: 10,
      page: 1,
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        page: this.page,
        per_page: this.limit,
        locale: this.language,
        embedded: 'organization'
      }
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),

    loadData() {
      this.callingAPI = true
      this.visibleUpdate = false
      getTags(this.params).then((response) => {
        this.allTags = response.result
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },
    createTag() {
      this.selectedTag = null
      this.visibleUpdate = true
    },
    updateTag(data) {
      this.selectedTag = data
      this.visibleUpdate = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allTags.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
      this.handleReloadConstant()
    },
    handleReloadConstant() {
      this.updateReloadConstant(dayjs().unix())
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-tab {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
