<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="!tag ? 'Thêm mới thẻ' : 'Chỉnh sửa thẻ'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Tên thẻ" prop="name">
        <el-input v-model="form.name" placeholder="Nhập vào tên thẻ"></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input v-model="form.description" type="textarea" placeholder="Nhập vào mô tả"></el-input>
      </el-form-item>
      <el-form-item v-if="!tag" label="Tổ chức" prop="organizationId">
        <el-select
          v-model="form.organizationId"
          class="w-100"
          filterable
          remote
          placeholder="Chọn tổ chức"
          :remote-method="searchOrganization"
          :loading="searching"
        >
          <el-option v-for="org in organizations" :key="org.id" :label="org.summary" :value="org.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdate">{{ !tag ? 'Tạo' : 'Cập nhật' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createTag, updateTag } from '@/services/tag'
import { getListOrganizations } from '@/services/organizations'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'UpdateTag',
  props: {
    tag: Object,
    visible: Boolean,
    placeTypes: Array
  },
  data() {
    return {
      callingAPI: false,
      searching: false,
      searchText: '',
      organizations: [],
      form: {
        name: this.tag ? this.tag.name : '',
        description: this.tag ? this.tag.description : '',
        organizationId: this.tag ? this.tag.organization_id : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên thẻ không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    dataInput() {
      const data = {
        locale: this.language,
        name: this.form.name,
        description: this.form.description
      }
      if (this.tag) {
        data.id = this.tag.id
      } else {
        data.code = `${this.form.name}_${uuidv4()}`
        if (this.form.organizationId) {
          data.organization_id = this.form.organizationId
        }
      }
      return data
    },

    params() {
      return {
        page: 1,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  methods: {
    getOrganizations() {
      this.searching = true
      const request = getListOrganizations(this.params)
      request.then((res) => {
        this.organizations = res.result
        this.searching = false
      }).catch(() => {
        this.organizations = []
        this.searching = false
      })
    },

    searchOrganization(text) {
      this.searchText = text
      setTimeout(() => {
        this.getOrganizations()
      }, 100)
    },

    handleUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.tag) {
            createTag(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Tạo mới thẻ thành công'
              })
              this.$emit('reload')
              this.$emit('reload-constant')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            updateTag(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Cập nhật thẻ thành công'
              })
              this.$emit('reload')
              this.$emit('reload-constant')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
